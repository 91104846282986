import React from 'react'
import { Text } from 'rebass/styled-components'
import { FaMarker } from 'react-icons/fa'
import { Container } from '../components/base'
import { withLayout } from '../components/Layout'

const NotFoundPage = () => (
  <Container
    justifyContent="center"
    alignItems="center"
    css={{ minHeight: '700px' }}
  >
    <FaMarker size="4em" />
    <Text as="h1">You are here!</Text>
    <Text as="h2">But nothing found for you #404</Text>
  </Container>
)

export default withLayout(NotFoundPage)
